import React from 'react';
import { Link } from 'react-router-dom';

import _ from 'underscore';
import config from '../config';

const twoDigits = (n) => {
	return parseInt(n) < 10 ? '0'+n : n;
}

const helpers = {
	urlTitles: {
		'hlutir': 'Hlutir',
		'einstaklingar': 'Einstaklingar',
		'person': 'Einstaklingar',
		'stadir': 'Staðir',
		'location': 'Staðir',
		'myndir': 'Myndir',
		'sofn': 'Söfn'

	},

	routerUrls: {
		'persons': '/einstaklingar/',
		'locations': '/stadir/',
		'objects': '/hlutir/',
		'collections': '/sofn/',

		'leit': '/leit/',

		'saveditems': '/handradinn/'
	},

	types: {
		'location': 'Staður',
		'person': 'Einstaklingur'
	},

	getPageRoot: (url) => {
		for (var key in helpers.routerUrls) {
			if (url.startsWith(helpers.routerUrls[key])) {
				return key;
			}
		}

		return false;
	},

	getPathWithTitles: (url) => {
		let urlFrags = _.compact(url.split('/'));

		let buildUrl = [];

		urlFrags.map((item, index) => {
			let itemTitle = helpers.urlTitles[item] || false;

			if (itemTitle) {
				buildUrl.push(itemTitle)
			}
		});

		return buildUrl.join(' / ');
	},

	metadataList: (data, url, idField, labelField, urlParam, lowerCase, linkTarget) => {
		return data && data.length > 0 ? data.map((item, index) => {
			let printLabel = (label) => lowerCase && index > 0 ? label.toLowerCase() : label;

			return <React.Fragment key={index}>
				{
					index > 0 && index == data.length-1 &&
					<React.Fragment> og </React.Fragment>
				}
				{
					index > 0 && index < data.length-1 && data.length > 1 &&
					<React.Fragment>, </React.Fragment>
				}
				{
					url && <Link to={url+(urlParam ? '?'+urlParam+'='+item[idField] : item[idField])} target={linkTarget}>{printLabel(item[labelField])}</Link>
				}
				{
					!url && printLabel((labelField ? item[labelField] : item))
				}
			</React.Fragment>
		}) : null;
	},

	createLocationParentString: (parent) => _.map(parent, (item, index) => item.name).join(' / '),

	parseSearchParams: (paramStr) => {
		let params = {};

		paramStr = decodeURI(paramStr);

		if (paramStr.substr(0, 1) == '?') {
			paramStr = paramStr.substr(1);
		}

		if (paramStr.split('&').length > 0) {
			paramStr.split('&').forEach((item, i) => {
				if (item.split('=')[0]) {
					params[item.split('=')[0]] = item.split('=')[1];
				}
			});
		}

		return params;
	},

	searchParamString: (params) => {
		let searchParams = [];

		for (var param in params) {
			searchParams.push(param+'='+
				(Array.isArray(params[param]) ? (
					params[param].map((item) => item.id || item).join(',')
				) : params[param])
			);
		}

		return '?'+searchParams.join('&');
	},

	updateSearchParams: (paramStr, newParams) => {
		let currentParams = helpers.parseSearchParams(paramStr);

		for (var param in newParams) {
			currentParams[param] = newParams[param];
		}

		let searchParams = [];

		for (var param in currentParams) {
			if (currentParams[param] != undefined && currentParams[param] != '') {
				searchParams.push(param+'='+
					(Array.isArray(currentParams[param]) ? (
						currentParams[param].map((item) => item.id || item).join(',')
					) : currentParams[param])
				);
			}
		}

		return '?'+searchParams.join('&');
	},

	routeBasePath: (path) => {
		let start = 3;

		let parts = path.split('/').slice(0, start);
		return parts.join('/');
	},

	isNumber: (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0),

	formatDuration: (millis) => {
		var minutes = Math.floor(millis / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);
		return twoDigits(minutes)+':'+twoDigits(seconds);
	},

	formatDate: (date, onlyYear, isoDate, includeTime) => {
		let ret = date || '';

		let safeParseInt = (i) => {
			return Number.isNaN(parseInt(i)) ? '' : parseInt(i);
		}

		let months = [
			'janúar',
			'febrúar',
			'mars',
			'apríl',
			'maí',
			'júní',
			'júlí',
			'ágúst',
			'september',
			'október',
			'nóvember',
			'desember'
		]

		try {
			if (isoDate && date != null) {
				let d = new Date(date);

				ret = onlyYear ?
					d.getFullYear() :
					d.getDate()+'. '+months[d.getMonth()]+' '+d.getFullYear()+
					(includeTime ? ', '+twoDigits(d.getHours())+':'+twoDigits(d.getMinutes()) : '')
				;
			}
			else {
				let d = [date.year, date.month, date.day]

				ret = onlyYear ? safeParseInt(d[0]) || '' : (d[2] ? safeParseInt(d[2])+'. ' : '')+(d[1] ? months[safeParseInt(d[1])-1]+' ' : '')+safeParseInt(d[0]);
			}
		}
		catch (e) {}

		return ret;
	},

	removeWhitespace: (str) => {
		return (str || '').replace(/\s/g, '');
	},

	trim: (str, maxLength) => {
		let div = document.createElement('div');
		div.innerHTML = str || '';
		str = div.textContent || div.innerText || '';
		return str ? (str.length > (maxLength || 300) ? str.substr(0, (maxLength || 300))+'...' : str) : '';
	},

	fetch: (url, callback, pageTitle, titleField, titleFunction) => {
		fetch(url)
			.then((result) => {
				if (result.status != 200) {
					callback({
						notfound: true
					});
				}
				return result.json();
			})
			.then((json) => {
				if (json.detail && json.detail == 'Not found.') {
					callback({
						notfound: true
					});

					return;
				}

				try {
					if (json.name || (titleField && json[titleField]) || titleFunction) {
						document.title = config.siteTitle+' | '+(
							json.name ? json.name :
							(titleField && json[titleField]) ? json[titleField] :
							titleFunction ? titleFunction(json) : ''
						);
					}
					else if (pageTitle) {
						document.title = config.siteTitle+' | '+pageTitle;
					}
				}
				catch(error) {
					document.title = config.siteTitle;
				}

				callback(json);
			});
	}
}

export default helpers;
