import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LoadingBar from './ui/LoadingBar';
import MapView from './MapView';
import _ from 'underscore';

import config from './../config';
import helpers from './../lib/helpers';

function LocationsMap(props) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	const history = useHistory();

	useEffect(() => {
		if (!data) {
			fetchData();
		}
	});

	useEffect(() => {
		fetchData();
	}, [props.searchParams, props.parentId])

	const fetchData = () => {
		setLoading(true);
		setData(null);

		let urlParams = [];

		if (props.searchParams != '') {
			let searchParams = helpers.parseSearchParams(props.searchParams);

			if (searchParams.nafn) {
				urlParams.push('search='+searchParams.nafn);
			}
		}

		urlParams.push('has_location=True');
		urlParams.push('limit=8000');

		fetch(config.apiRoot+'/api/object_collections/?'+urlParams.join('&'))
			.then((result) => result.json())
			.then((json) => {
				let grouped = _.groupBy(_.filter(json.results, (item) => item.place.lat != null && item.place.lng != null), item => item.place.id);

				let mapData = Object.keys(grouped).map((key) => {
					let place = grouped[key][0].place;
					let item = grouped[key];

					return {
						id: place.id,
						label: place.name,
						latLng: [place.lat, place.lng],
						radius: item.reduce((memo, num) => memo + num.object_count, 0),
						popupContent: '<h4>'+place.name+'</h4>'+
							'<p>'+
							item.map(c => '<a href="'+helpers.routerUrls['collections']+c.id+'">'+c.name+'</a>').join('<br/>')+
							'</p>'
					}
				});

				let _mapData = _.filter(json.results, (item) => item.place.lat != null && item.place.lng != null).map((item) => {
					let retObj = {
						id: item.id,
						label: item.name,
						latLng: [item.place.lat, item.place.lng],
						radius: item.object_count,
						urlBase: helpers.routerUrls['collections']
					}

					return retObj;
				});

				setData(mapData);
				setLoading(false);

				if (props.onResponse) {
					props.onResponse(json.results);
				}
			});
	}

	return (
		<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
			<MapView mapData={data || []} circleMarkers={true} />
		</div>
	)
}

export default LocationsMap;
