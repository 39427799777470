import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Lang from './lang/Lang';

Lang.fetchLangData(() => {
	if (window.localStorage.currentLang) {
		console.log('set language from localStorage')
		Lang.setCurrentLang(window.localStorage.currentLang);
	}	
});

window.Lang = Lang;
Lang.collect = false;

window.l = function(phrase) {
	return Lang.get(phrase);
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
