import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LoadingBar from './ui/LoadingBar';
import LocationParentsView from './ui/LocationParentsView';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import LocationsMap from './LocationsMap';
import ListHeader from './ui/ListHeader';
import NoResults from './ui/NoResults';
import SortTable from './ui/SortTable';
import locationIcon from './../images/location.png';

import config from './../config';
import helpers from './../lib/helpers';

function PlaceList(props) {
	const [data, setData] = useState(null);
	const [currentSearchTerm, setCurrentSearchTerm] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	const history = useHistory();

	useEffect(() => {
		fetchData();
	}, [props.locationId, props.searchParams, props.parentId]);

	useEffect(() => {
	}, []);

	const fetchData = () => {
		setLoading(true);
		setData(null);
		setCurrentSearchTerm(null)

		let urlParams = [];

		if (props.locationId) {
			urlParams.push('location='+props.locationId)
		}

		if (props.parentId) {
			urlParams.push('parent='+props.parentId)
		}

		if (props.searchParams) {
			let params = helpers.parseSearchParams(props.searchParams)

			if (params.nafn) {
				urlParams.push('name='+params.nafn)
				setCurrentSearchTerm(params.nafn)
			}

			if (params.hlutverk) {
				urlParams.push('role='+params.hlutverk)
			}

			if (params.tilheyrir) {
				urlParams.push('parent='+params.tilheyrir)
			}

			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}

			if (params.rodun) {
				urlParams.push('order='+params.rodun)
			}
		}

		helpers.fetch(config.apiRoot+'/api/places/?'+urlParams.join('&'), (json) => {
			let results = json.results;

			setData(results);
			setLoading(false);
			setNextUrl(json.next);
			setPreviousUrl(json.previous);
			setResultCount(json.count);

			if (props.onResponse) {
				props.onResponse(results);
			}
		});
	}

	let placeTable = <div tabtitle={window.l('Listi')}>
		{
			data && data.length > 0 &&
			<React.Fragment>
				<ListHeader resultCount={resultCount} />
				<SortTable searchParams={props.searchParams}
					headers={[
						{
							label: '',
							width: 25
						},
						{
							label: window.l('Nafn'),
							//sortValue: 'name'
						},
						{
							label: window.l('Tegund'),
							//sortValue: 'location_type'
						},
						{
							label: window.l('Svæði')
						}
					]}
				>
					{
						data.map((item, index) => <tr key={index}>
							<td>
								{
									item.lat && item.lng && <img style={{width: 18}} src={locationIcon} />
								}
							</td>
							<td>
								<Link to={helpers.routerUrls['locations']+item.id}>
									{
										item.name
									}
								</Link>
							</td>
							<td>
								{
									item.location_type && item.location_type && helpers.metadataList([item.location_type], helpers.routerUrls['stadir']+'?tegund=', 'id', 'name')
								}
							</td>
							<td>
								{
									item.parent && item.parent.length > 0 &&
									<LocationParentsView skipFirst={true} location={item} />
								}
							</td>
						</tr>)
					}
				</SortTable>
				{
					resultCount > config.pageSize &&
					<PaginationButtons resultCount={resultCount}
						previousUrl={previousUrl}
						nextUrl={nextUrl} />
				}
			</React.Fragment>
		}
		{
			data && data.length == 0 && !loading &&
			<NoResults searchTerm={currentSearchTerm} />
		}
	</div>;

	return (
		<React.Fragment>
			{
				props.enableFilter &&
				<ListFilter filters={[
					{
						label: window.l('Nafn'),
						paramName: 'nafn',
						type: 'text'
					},
					{
						label: window.l('Hlutverk staðar'),
						paramName: 'hlutverk',
						type: 'select',
						selectOptions: {
							endpoint: 'place_roles',
							labelField: 'role',
							valueField: 'id'
						}
					},
					{
						label: window.l('Tilheyrir'),
						paramName: 'tilheyrir',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'places',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							disableLargeList: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.parent) &&
									<React.Fragment>
										<br/>
										<small>{helpers.createLocationParentString(item.parent)}</small>
									</React.Fragment>
								}
							</div>
						}
					}
				]}
				currentParams={props.searchParams}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
				<LoadingBar />

				<React.Fragment>
					{
						props.children
					}
					{
						props.disableMap && placeTable
					}

					<LocationsMap parentId={props.parentId} searchParams={props.searchParams} />
					
					{
						placeTable
					}

				</React.Fragment>

				</div>
			</React.Fragment>
	)
}

export default PlaceList;
