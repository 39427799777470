import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';
import ListHeader from './ui/ListHeader';
//import TableRow from './ui/TableRow';

import config from './../config';
import helpers from './../lib/helpers';

function PersonList(props) {
	const [data, setData] = useState(null);
	const [currentSearchTerm, setCurrentSearchTerm] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	useEffect(() => {
		if (!data) {
			fetchData();
		}
	});

	useEffect(() => {
		fetchData();
	}, [props.locationId, props.searchParams, props.folkloreRecorderId]);

	const fetchData = () => {
		if (loading == true) {
			return;
		}

		setLoading(true);
		setData(null);
		setCurrentSearchTerm(null)

		let urlParams = [];

		if (props.locationId) {
			urlParams.push('location='+props.locationId)
		}

		if (props.searchParams) {
			let params = helpers.parseSearchParams(props.searchParams)

			if (params.nafn) {
				urlParams.push('name='+params.nafn)
				setCurrentSearchTerm(params.nafn)
			}

			if (params.kyn) {
				urlParams.push('gender='+params.kyn)
				setCurrentSearchTerm(params.kyn)
			}

			if (params.hlutverk) {
				urlParams.push('role='+params.hlutverk)
			}

			if (params.flutningsar && params.flutningsar_leit) {
				urlParams.push('emigration_years='+params.flutningsar)
			}

			if (params.stadur) {
				urlParams.push('location='+params.stadur)
				urlParams.push('lookup_parent=True')
			}

			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}

		}

		helpers.fetch(config.apiRoot+'/api/persons/?'+urlParams.join('&'), (json) => {
			setData(json.results);
			setLoading(false);
			setNextUrl(json.next);
			setPreviousUrl(json.previous);
			setResultCount(json.count);

			if (props.onResponse) {
				props.onResponse(json.results);
			}
		});
	}

	return (
		<React.Fragment>
			{
				props.enableFilter &&
				<ListFilter filters={[
					{
						label: window.l('Nafn'),
						paramName: 'nafn',
						type: 'text'
					},
					{
						label: window.l('Kyn'),
						paramName: 'kyn',
						type: 'select',
						selectOptions: {
							options: [
								{
									label: window.l('Karl'),
									value: 'karl'
								},
								{
									label: window.l('Kona'),
									value: 'kona'
								},
								{
									label: window.l('Óþekkt'),
									value: 'óþekkt'
								},
								{
									label: window.l('Annað'),
									value: 'annað'
								}
							]
						},
					},
					{
						label: window.l('Hlutverk'),
						paramName: 'hlutverk',
						type: 'select',
						selectOptions: {
							endpoint: 'person_roles',
							labelField: 'role',
							valueField: 'id'
						}
					},
					{
						label: window.l('Tengdur staður'),
						paramName: 'stadur',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'places',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							disableLargeList: true,
							singleSelect: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.parent) &&
									<React.Fragment>
										<br/>
										<small>{helpers.createLocationParentString(item.parent)}</small>
									</React.Fragment>
								}
							</div>
						}
					},
					{
						label: window.l('Flutningsár'),
						type: 'heading'
					},
					{
						label: window.l('Leita eftir flutningsári'),
						paramName: 'flutningsar_leit',
						type: 'checkbox'
					},
					{
						paramName: 'flutningsar',
						type: 'range',
						fullWidth: true,
						rangeOptions: {
							endpoint: 'emigration_years',
							valueField: 'year_of_emigration',
							graphHeightField: 'count'
						}
					}
				]}
				currentParams={props.searchParams}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
				<LoadingBar />
				{
					data && data.length > 0 &&
					<React.Fragment>
						{
							props.children
						}
						<ListHeader resultCount={resultCount} />
						<table className="table">
							<thead>
								<tr>
									<th>{window.l('Nafn')}</th>
									<th>{window.l('Fæðingar- og dánarár')}</th>
									<th>{window.l('Flutningsár')}</th>
								</tr>
							</thead>
							<tbody>
								{
									data.map((item, index) => <tr key={index} hoverSettings={{
										endpoint: config.apiRoot+'/api/persons/'+item.id,
										fields: ['name', 'life'],
										template: '<strong>{name}</strong><br/>{life}'
									}}>
										<td>
											{
												/*
												item.file && item.file.path &&
												<img style={{
													maxWidth: '30px',
													maxHeight: '30px',
													marginRight: '20px'
												}} src={config.mediaUrl+item.file.path} />
												*/
											}
											<Link to={helpers.routerUrls['persons']+item.id}>
												{
													item.name
												}
											</Link>
										</td>
										<td>
											{
												((item.birth && item.birth.year) || (item.death && item.death.year)) &&
												<React.Fragment>{(item.birth ? item.birth.year : '')+'–'+(item.death ? item.death.year : '')}</React.Fragment>
											}
										</td>
										<td>{item.year_of_emigration}</td>
									</tr>)
								}
							</tbody>
						</table>
						{
							resultCount > config.pageSize &&
							<PaginationButtons resultCount={resultCount}
								previousUrl={previousUrl}
								nextUrl={nextUrl} />
						}
					</React.Fragment>
				}
				{
					data && data.length == 0 && !loading &&
					<NoResults searchTerm={currentSearchTerm}></NoResults>
				}
			</div>
		</React.Fragment>
	)
}

export default PersonList;
