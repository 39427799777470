import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import config from './../../config';
import helpers from './../../lib/helpers';

function FrontPage(props) {
	const [pageParts, setPageParts] = useState(null);
	const [initialized, setInitialized] = useState(false);

	let history = useHistory();

	let isExternal = (url) => {
		var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
		if (url.toLowerCase().indexOf('.pdf') > -1) return true;
		if (url.startsWith('https://') || url.startsWith('http://')) return true;
		if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== window.location.protocol) return true;
		if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":("+{"http:":80,"https:":443}[window.location.protocol]+")?$"), "") !== window.location.host) return true;
		return false;
	};

	let htmlContentClickHandler = (event) => {
		let linkEl = event.target.closest('a');

		if (linkEl) {
			let linkUrl = linkEl.getAttribute('href');

			if (!isExternal(linkUrl) && (!linkEl.getAttribute('target'))) {
				event.preventDefault();
				history.push(linkUrl);
			}
		}
	};

	useEffect(() => {
		window.eventBus.addEventListener('Lang.languageChanged', () => {
			fetchData();
		});

		window.eventBus.addEventListener('Lang.languageDataUpdate', () => {
			fetchData();
		});
	});

	const fetchData = () => {
		let urlParams = [];
		urlParams.push('page='+props.url);

		urlParams.push('lang='+(window.currentLang || 'is'));

		helpers.fetch(config.apiRoot+'/api/pageparts/?'+urlParams.join('&'), (json) => {
			setPageParts(json.results);

			setTimeout(() => setInitialized(true), 100);
		});
	};

	useEffect(() => {
		fetchData();
	}, [props.url]);

	return <div className="container wide-container">
		<div className={'row mb-4 front-boxes'+(initialized ? ' initialized' : '')}>
			{
				pageParts && pageParts.map((part, index) => {
					return <div key={index} className={'box '+part.classname}>
						<div className={'box-content '+(part.boxclassname || '')}>
							{
								!part.content.startsWith('component:[') &&
								<div onClick={htmlContentClickHandler}
									dangerouslySetInnerHTML={{__html: part.content}} />
							}
						</div>
					</div>
				})
			}
		</div>
	</div>
}

export default FrontPage;
