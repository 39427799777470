import React, { useState, useEffect } from 'react';

function SiteFooter(props) {
	return (
		<div className="site-footer">
			<div className={'container'+(props.frontPage ? ' wide-container' : '')}>
				<div className="row">

					<div className="col-12 col-md-8">

						<div className="footer-content">
							<p><strong>Stofnun Árna Magnússonar í íslenskum fræðum</strong><br/>
								Eddu<br/>
								Arngrímsgötu 5<br/>
								107 Reykjavík
							</p>
						</div>

					</div>

				</div>
			</div>
		</div>
	)
}

export default SiteFooter;
