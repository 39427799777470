import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import ListHeader from './ui/ListHeader';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';
import CollectionsMap from './CollectionsMap';

import config from './../config';
import helpers from './../lib/helpers';

function CollectionsList(props) {
	const [data, setData] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	const history = useHistory();

	useEffect(() => {
		if (!data) {
			if (props.data) {
				setData(props.data);
				setLoading(false);
			}
			else {
				fetchData();
			}
		}
	});

	useEffect(() => {
		if (!props.data) {
			fetchData();
			setLoading(false);
		}
	}, [props.locationId, props.searchParams]);

	const fetchData = () => {
		setLoading(true);
		setData(null);

		let urlParams = [];

		if (props.searchParams) {
			let params = helpers.parseSearchParams(props.searchParams);

			if (params.nafn) {
				urlParams.push('search='+params.nafn)
			}

			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}
		}
		if (props.placeId) {
			urlParams.push('location='+props.placeId);
		}

		helpers.fetch(config.apiRoot+'/api/object_collections/?'+urlParams.join('&'), (json) => {
			setData(json.results);
			setLoading(false);
			setNextUrl(json.next);
			setPreviousUrl(json.previous);
			setResultCount(json.count);

			if (props.onResponse) {
				props.onResponse(json.results);
			}
		});
	}

	let table = <>
		{
			data && data.length > 0 &&
			<>
				<ListHeader resultCount={resultCount} />
				<table className="table">
					<thead>
						<tr>
							<th>{window.l('Heiti')}</th>
							<th>{window.l('Lýsing')}</th>
						</tr>
					</thead>
					<tbody>
						{
							data.map((item, index) => {
								let dataItem = (props.data ? item.object : item);

									return <tr key={index}>
									<td>
										<Link to={helpers.routerUrls['collections']+dataItem.id}>
											{
												dataItem.name
											}
										</Link>
									</td>
									<td>{dataItem.description}</td>
								</tr>;
							})
						}
					</tbody>
				</table>
				{
					resultCount > config.pageSize &&
					<>
						{
							props.moreLink && <div className="mb-3">
								<div className="pagination">
									<Link className="page-link btn-info active" to={props.moreLink}>{window.l('Sýna allt')}</Link>
								</div>
							</div>
						}
						{
							!props.moreLink && <PaginationButtons resultCount={resultCount}
							previousUrl={previousUrl}
							nextUrl={nextUrl} />
						}
					</>
				}
			</>
		}
	</>

	return (
		<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
			<LoadingBar />
			{
				props.enableFilter &&
				<ListFilter filters={[
					{
						label: window.l('Heiti'),
						paramName: 'nafn',
						type: 'text'
					}
				]}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			{
				data && data.length > 0 &&
				<>
					{
						props.children
					}
					{
						!props.hideMap && <CollectionsMap searchParams={props.searchParams} />
					}
					{
						table
					}
				</>
			}
			{
				data && data.length == 0 && !loading &&
				<NoResults />
			}
		</div>
	)
}

export default CollectionsList;
