import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import _ from 'underscore';

import helpers from './../lib/helpers';
import config from './../config';

function PaginationButtons(props) {
	let pageButtons = [];
	let history = useHistory();

	let maxButtonCount = 7;

	let currentPage = parseInt(history.location.search != '' ? helpers.parseSearchParams(history.location.search).sida || 1 : 1);

	let startPage = currentPage;

	let pageSize = props.pageSize || config.pageSize;

	if (startPage > Math.floor(maxButtonCount/2) && props.resultCount/pageSize > maxButtonCount) {
		startPage = startPage-(Math.floor(maxButtonCount/2));
	}
	else {
		startPage = 1;
	}

	let maxPageCount = Math.ceil((props.resultCount/pageSize))+1;

	maxPageCount = maxPageCount > startPage+maxButtonCount ? startPage+maxButtonCount : maxPageCount;
	maxPageCount = maxPageCount > props.resultCount/pageSize ? Math.ceil(props.resultCount/pageSize) : maxPageCount;

	const buildUrl = (page) => {
		return history.location.pathname+helpers.updateSearchParams(history.location.search, {sida: page});
	};

	if (startPage > 1) {
		pageButtons.push(
			<div key="1" className="page-item">
				<Link className={'page-link btn-info'} to={buildUrl(1)} aria-label="Síða 1">
					<span aria-hidden="true">1</span>
				</Link>
			</div>
		);

		if (startPage > 2) {
			pageButtons.push(
				<div key="..." className="page-item">
					<div className={'page-link btn-info'}>
						<span aria-hidden="true">...</span>
					</div>
				</div>
			);
		}
	}

	for (let i = startPage; i<maxPageCount; i++) {
		pageButtons.push(
			<div key={i} className="page-item">
				<Link className={'page-link btn-info'+(i == currentPage ? ' active' : '')} to={buildUrl(i)} aria-label={'Síða '+i}>
					<span aria-hidden="true">{i}</span>
				</Link>
			</div>
		);
	}

	if (Math.ceil(props.resultCount/pageSize) > maxPageCount) {
		pageButtons.push(
			<div key="...." className="page-item">
				<div className={'page-link btn-info'}>
					<span aria-hidden="true">...</span>
				</div>
			</div>
		);
	}

	pageButtons.push(
		<div key={Math.ceil((props.resultCount/pageSize))} className="page-item">
			<Link className={'page-link btn-info'} to={buildUrl(Math.ceil(props.resultCount/pageSize))} aria-label="Síða">
				<span aria-hidden="true">{Math.ceil((props.resultCount/pageSize))}</span>
			</Link>
		</div>
	);

	return <div className={'mb-3'+(props.className ? ' '+props.className : '')}>
			<div className="pagination">
				{
					props.previousUrl &&
					<div className="page-item">
						<Link className={'page-link btn-info'} to={buildUrl(currentPage-1)} aria-label="Fyrri síða">
							<span aria-hidden="true"><span className="arrows">&laquo;</span> {window.l('Fyrri síða')}</span>
							<span className="sr-only">{window.l('Fyrri síða')}</span>
						</Link>
					</div>
				}
				{
					pageButtons
				}
				{
					props.nextUrl &&
					<div className="page-item">
						<Link className={'page-link btn-info'} to={buildUrl(currentPage+1)} aria-label="Næsta síða">
							<span aria-hidden="true">{window.l('Næsta síða')} <span className="arrows">&raquo;</span></span>
							<span className="sr-only">{window.l('Næsta síða')}</span>
						</Link>
					</div>
				}
		</div>
	</div>;
}

export default PaginationButtons;
