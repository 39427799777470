import React, { Component, Suspense, createRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, withRouter } from "react-router-dom";
import _ from 'underscore';

import EventBus from 'eventbusjs';
import logo from './images/logo.png';

//import SearchBox from './components/SearchBox.js';
import SiteFooter from './components/ui/SiteFooter.js';
//import AudioView from './components/AudioView.js';
//import PlaceView from './components/PlaceView.js';
//import PersonView from './components/PersonView.js';
//import FolkloreView from './components/FolkloreView.js';
import GenericList from './components/GenericList.js';
import ContentWrapper from './components/ui/ContentWrapper.js';

import PlaceList from './components/PlaceList';
import PersonList from './components/PersonList';
import ObjectList from './components/ObjectList';
import CollectionsList from './components/CollectionsList';

import Loader from './components/ui/Loader';
import SiteTitle from './components/ui/SiteTitle';
import PageMenu from './components/ui/PageMenu';
import PageView from './components/ui/PageView';
import GoogleAnalytics from './components/ui/GoogleAnalytics';
import CollectionsMap from './components/CollectionsMap';

import helpers from './lib/helpers';
//import localLibrary from './lib/localLibrary';

const PlaceView = React.lazy(() => import('./components/PlaceView'));
const PersonView = React.lazy(() => import('./components/PersonView'));
const ObjectView = React.lazy(() => import('./components/ObjectView'));
const CollectionView = React.lazy(() => import('./components/CollectionView'));

let l = window.l;

class App extends Component {
	constructor(props) {
		super(props);

		this.router = createRef();

		window.eventBus = EventBus;

		window.eventBus.addEventListener('Lang.languageChanged', function() {
			console.log('languageChanged')
			this.forceUpdate();
		}.bind(this));

		window.eventBus.addEventListener('Lang.languageDataUpdate', function() {
			console.log('languageDataUpdate')
			this.forceUpdate();
		}.bind(this));

		this.menuButtonClickHandler = this.menuButtonClickHandler.bind(this);

    /*
		localLibrary.registerSignal(function() {
			this.forceUpdate();
		}.bind(this))
    */;

		this.state = {
			sitePart: [],
			currentPage: '',
			currentUrl: '/',
			menuOpen: false
		}
	}

	componentDidMount() {
		l = window.l;

		this.router.current.history.listen((event) => {
			// Ekki alltaf skrolla upp þegar slóð breytist, ekki þegar hlustað er á nýtt hljóðrit eða þegar flakkað er á milli tabba - TODO
			if (helpers.routeBasePath(this.state.currentUrl) != helpers.routeBasePath(event.pathname)) {
				window.scrollTo(0, 0);
			}

			this.checkSitePart(event.pathname)

			this.setState({
				currentUrl: event.pathname,
				menuOpen: false
			});
		});

		document.addEventListener('mousedown', function(event) {
			if (!this.state.menuOpen) {
				return;
			}

			if (this.refs.menuButton.contains(event.target)) {
				event.stopPropagation();

				return;
			}

			if (this.refs.menuContent && !this.refs.menuContent.contains(event.target)) {
				this.setState({
					menuOpen: false
				})
			}
		}.bind(this));

		window.eventBus.addEventListener('AppMenu.close', function() {
			this.setState({
				menuOpen: false
			});
		}.bind(this));

		this.setState({
			currentUrl: this.router.current.history.location.pathname
		});

		this.checkSitePart(this.router.current.history.location.pathname);

		try {
			if (this.router.current.history.location.hash != '') {
				if (this.router.current.history.location.hash.startsWith('#lang=')) {
					setTimeout(() => window.Lang.setCurrentLang(this.router.current.history.location.hash.split('=')[1]), 500);
				}
			}
		}
		catch (e) {}
	}

	menuButtonClickHandler(event) {
		event.stopPropagation();
		if (!this.state.menuOpen) {
			this.refs.menuContent.scrollTop = 0;
		}

		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}

	checkSitePart(sitePart) {
		let urlFrags = sitePart.toLowerCase().split('/');
		let urlFragsCompact = _.compact(sitePart.toLowerCase().split('/').filter((part) => !helpers.isNumber(part)));

		let currentPage = urlFragsCompact.length > 0 ? urlFragsCompact[urlFragsCompact.length-1] : '';

		if (urlFrags.length > 0) {
			if (helpers.isNumber(urlFrags[urlFrags.length-1])) {
				currentPage += '-item';
			}
		}

		let siteParts = [];

		let headerTitle;
		let headerSubTitle;

		this.setState({
			sitePart: siteParts,
			currentPage: currentPage,
			frontPage: sitePart == '/',
			headerTitle: headerTitle,
			headerSubTitle: headerSubTitle
		});
	}

	audioPlayerVisibleHandler() {
		document.body.classList.add('has-docked-control');
	}

	render() {
		return (
			<div className={'app page-'+this.state.currentPage}>
				<Router ref={this.router}>

					<Switch>

						<Route path="/">

							<div className="header">
								<div className="container position-relative">

									<div className="logo-wrapper">
										<div className="logo">
											<img src={logo} className="mr-4" />
										</div>
										
										<div className="text">
											<div>{window.l('Handrit íslenskra vesturfara')}</div>
										</div>

									</div>

									<button className="btn-menu hidden-mobile-up" ref="menuButton" onClick={this.menuButtonClickHandler}>
										<div className="hamburger-icon">
											<div className="icon-middle" />
											<div className="icon-before-after" />
										</div>
									</button>

									<PageMenu className="hidden-mobile" />
								</div>
							</div>

							{/*
							<div className="search-container">
								<div className="container">
									<SearchBox />
								</div>
							</div>
							*/}

							<Switch>

								<Route exact path="/">
									<PageView url="/" />

									<div className="container">
										<h4>{window.l('Kort yfir söfn')}</h4>
										<CollectionsMap />
									</div>
								</Route>

								<React.Fragment>

										<Switch>

											<div className="container" computedMatch={null}>
												<Switch>
													{/*
													<Route exact path={helpers.routerUrls['saveditems']} render={(routeProps) => (
														<React.Fragment>
															<SiteTitle title="Handraðinn" />
															<SavedItemsView />
														</React.Fragment>
													)} />
													*/}

													<Route path={helpers.routerUrls['locations']+':id([0-9]+)/:tab?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<PlaceView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['locations']+':tab?'} render={(routeParams) => {
														return <ContentWrapper>
															<SiteTitle title="Staðsetningar" />
															<PageView url={helpers.routerUrls['locations']} fallback={<h2>Staðir</h2>} />
														 	<PlaceList searchParams={routeParams.location.search} useRouter={true} enableFilter={true} {...routeParams} />
														</ContentWrapper>
													}} />

													<Route path={helpers.routerUrls['persons']+':id/:tab?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<PersonView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['persons']} render={(routeParams) => {
														return <ContentWrapper>
															<SiteTitle title="Einstaklingar" />
															<PageView url={helpers.routerUrls['persons']} fallback={<h2>Einstaklingar</h2>} />
															<PersonList searchParams={routeParams.location.search} enableFilter={true} />
														</ContentWrapper>
													}} />

													<Route path={helpers.routerUrls['objects']+':id/:tab?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<ObjectView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['objects']+':tab?'} render={(routeParams) => {
														return <ContentWrapper>
															<SiteTitle title="Hlutir" />
															<PageView url={helpers.routerUrls['objects']} fallback={<h2>Hlutir</h2>} />
														 	<ObjectList searchParams={routeParams.location.search} useRouter={true} enableFilter={true} {...routeParams} />
														</ContentWrapper>
													}} />


													<Route path={helpers.routerUrls['collections']+':id/:tab?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<CollectionView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['collections']+':tab?'} render={(routeParams) => {
														return <ContentWrapper>
															<SiteTitle title="Söfn" />
															<PageView url={helpers.routerUrls['collections']} fallback={<h2>Hlutir</h2>} />
														 	<CollectionsList searchParams={routeParams.location.search} useRouter={true} enableFilter={true} {...routeParams} />
														</ContentWrapper>
													}} />

													{/*
													<Route path={helpers.routerUrls['leit']+':searchTerm/:entityType?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<SearchResults searchParams={routeParams.location.search} searchTerm={routeParams.match.params.searchTerm} entityType={routeParams.match.params.entityType} {...routeParams} />
														</Suspense>
													}} />
													*/}

													<Route path={helpers.routerUrls['leit']}>
														<Redirect to={'/'} />
													</Route>

													<Route exact path={['/*']} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<PageView />
														</Suspense>
													}} />

												</Switch>
											</div>

										</Switch>

								</React.Fragment>

							</Switch>


							<div className={'app-menu'+(this.state.menuOpen ? ' open' : '')} ref="menuContent">
								<div className="container">

									<button className="btn btn-link float-right" onClick={
										function() {
											this.setState({
												menuOpen: false
											});
										}.bind(this)}>
										<div className="hamburger-icon black open">
											<div className="icon-middle" />
											<div className="icon-before-after" />
										</div>
									</button>

									<h5 className="mb-4 pt-2">{window.l('Handrit íslenskra vesturfara')}</h5>

									<PageMenu className="hidden-mobile-up" />

								</div>
							</div>

							<GoogleAnalytics />

							<SiteFooter frontPage={this.state.frontPage} />

						</Route>
					</Switch>

				</Router>

			</div>
		)
	}
}

export default App;
