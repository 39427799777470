import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import LoadingBar from './ui/LoadingBar';
import PaginationButtons from './PaginationButtons';
import ListFilter from './filters/ListFilter';
import NoResults from './ui/NoResults';

import config from './../config';
import helpers from './../lib/helpers';

function GenericList(props) {
	const [data, setData] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		setData(null);

		let urlParams = [];

		if (props.searchParams) {
			let searchParams = helpers.parseSearchParams(props.searchParams);

			if (props.filters) {
				props.filters.forEach((filter, i) => {
					if (filter.paramName in searchParams) {
						urlParams.push(filter.apiParam+'='+searchParams[filter.paramName]);
					}
				});
			}

			if (searchParams.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(searchParams.sida-1)))
			}
		}

		helpers.fetch(config.apiRoot+'/api/'+props.apiEndPoint+(urlParams.length > 0 ? '?'+urlParams.join('&') : ''), (json) => {
			setData(json.results);
			setNextUrl(json.next);
			setPreviousUrl(json.previous);
			setResultCount(json.count);
			setLoading(false);
		});
	}, [props.apiEndPoint, props.searchParams]);

	return (
		<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
			<LoadingBar />
			{
				props.filters &&
				<ListFilter filters={props.filters}
					currentParams={props.searchParams}
					onFilter={(event) => {
						event.sida = null;
						props.history.push(props.history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
					}} />
			}
			{
				data && data.length > 0 &&
				<React.Fragment>
					{
						props.children
					}
					<table className="table">
						<thead>
							<tr>
								<th>Nafn</th><th></th>
							</tr>
						</thead>
						<tbody>
							{
								data.map((item, index) => <tr key={index}>
									<td>
										<Link to={(props.viewUrl || '')+item[props.idField || 'id']}>
											{
												item[props.labelField || 'name']
											}
										</Link>
									</td>
									<td>
										{
											props.contentField &&
											item[props.contentField]
										}
									</td>
								</tr>)
							}
						</tbody>
					</table>
					{
						resultCount > config.pageSize &&
						<PaginationButtons resultCount={resultCount}
							previousUrl={previousUrl}
							nextUrl={nextUrl} />
					}
				</React.Fragment>
			}
			{
				data && data.length == 0 && !loading &&
				<NoResults />
			}
		</div>
	)
}

export default withRouter(GenericList);
