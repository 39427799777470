import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import helpers from './../../lib/helpers';

function LocationParentsView(props) {
	const [open, setOpen] = useState(false);

	return (
		props.location &&
		<React.Fragment>
			{
				!props.skipFirst &&
				<Link className={'h4'} to={helpers.routerUrls['locations']+props.location.id}>{props.location.name}<br/></Link>
			}
			{
				props.location.parent.length > 0 &&
				props.location.parent.map((parent, index) => <React.Fragment key={index}>
					{
						index > 0 &&
						<React.Fragment> / </React.Fragment>
					}
					<Link to={helpers.routerUrls['locations']+parent.id}>{parent.name}</Link>
				</React.Fragment>)
			}
			{/*
			<div class="dropdown">
				<button className="btn btn-sm btn-secondary dropdown-toggle" type="button" onClick={() => setOpen(!open)}>
					{props.location.name}
				</button>
				<div className={'dropdown-menu'+(open ? ' show' : '')}>
					{
						props.location.parent.map((parent, index) => <Link key={index} to={'/stadur/'+parent.id} className="dropdown-item">{parent.name}</Link>)
					}
				</div>
			</div>
			*/}
		</React.Fragment>
	)
}

export default LocationParentsView;
