import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import _ from 'underscore';

import helpers from './../../lib/helpers';

function Breadcrumb(props) {
	const history = useHistory();

	let urlFrags = _.compact(history.location.pathname.split('/'));

	let buildUrl = [];

	return (
		<nav aria-label="breadcrumb">
			<ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to={'/'}>{window.l('Forsíða')}</Link></li>
				{
					urlFrags.map((item, index) => {
						let itemTitle = helpers.isNumber(item) ? (props.currentElTitle || '') : (helpers.urlTitles[item] || props.currentElTitle || '');

						buildUrl.push(urlFrags[index])

						return <li key={index} className="breadcrumb-item"><Link to={'/'+buildUrl.join('/')+'/'}>{window.l(itemTitle)}</Link></li>
					})
				}
			</ol>
		</nav>
	)
}

export default Breadcrumb;
