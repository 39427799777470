export default {
	pageSize: 50,

	apiRoot: process.env.REACT_APP_API_ROOT,
	mediaUrl: 'https://geymd.arnastofnun.is/vesturheimsrit/',

	siteRoot: process.env.REACT_APP_SITE_ROOT,

	siteTitle: 'Handrit í Vesturheimi',

	localLibraryName: 'vesturheimsrit',

	gaTrackingId: 'G-H7854LCBM9'
}
