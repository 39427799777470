import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import L from 'leaflet'
import 'leaflet.markercluster';
import 'leaflet.heat';
import _, { min } from 'underscore';
import helpers from '../lib/helpers';

class MapView extends Component {
	constructor(props) {
		super(props);

		this.mapContainer = React.createRef();
		this.mapCenter = [56.130367, -106.346771];

		this.markerClusterSettings = {
			maxClusterRadius: 40
		};

		this.state = {
		};
	}

	componentDidMount() {
		this.esriWorldTopMap = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
			attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
		});

		this.layerOpenStreetMap = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
			maxZoom: 19,
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
		});

		this.esriWorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
			attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
		});

		let baseLayers = {
			'Esri World Topo Map': this.esriWorldTopMap,
			'ESRI loftmynd': this.esriWorldImagery,
		};

		if (this.props.circleMarkers) {
			this.layerGroup = L.layerGroup();
		}
		else {
			this.layerGroup = L.markerClusterGroup(this.markerClusterSettings);
		}

		/*
		this.heatLayer = L.heatLayer([], {
			minOpacity: 0.35,
			radius:18,
			blur:15
		});

		let overlayLayers = {
			'Punktar': this.markersClusterLayer,
			'Hitakort': this.heatLayer
		};
		*/

		this.map = L.map(this.mapContainer.current, {
			minZoom: 2,
			maxZoom: 16,
			layers: [this.esriWorldTopMap, this.layerGroup]
		}).setView(this.mapCenter, 4);

		L.control.layers(baseLayers).addTo(this.map);
		//L.control.layers(baseLayers, overlayLayers).addTo(this.map);

		this.markers = [];

		if (this.props.mapData && this.props.mapData.length > 0) {
			this.addMarkers(this.props.mapData);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.mapData && this.props.mapData != prevProps.mapData) {
			this.addMarkers(this.props.mapData);
		}
	}

	addMarkers(data) {
		this.layerGroup.clearLayers();
		this.markers = [];

		let bounds = [];
		let heatPoints = [];

		let minRadius, maxRadius, minCircleRadius, maxCircleRadius, range;

		if (this.props.circleMarkers) {
			minRadius = _.min(_.pluck(data, 'radius'));
			maxRadius = _.max(_.pluck(data, 'radius'));

			minCircleRadius = 10;
			maxCircleRadius = 35;

			range = (value) => (value - minRadius) * (maxCircleRadius - minCircleRadius) / (maxRadius - minRadius) + minCircleRadius;
		}

		data.map((item) => {
			let options = {
				title: item.label
			};

			if (this.props.circleMarkers) {
				options.radius = range(item.radius);
				options.weight = 2;
				options.color = '#e35e00';
				options.fillColor = '#ff1400';
				options.zIndexOffset = item.radius;
			}

			let marker = (this.props.circleMarkers && data.length > 1 ? L.circleMarker : L.marker)(item.latLng, options).bindPopup(
				item.popupContent || '<h4>'+item.label+(item.description ? '<br/><small>'+item.description+'</small>' : '')+'</h4>'+
				'<p>'+
				(item.district ? item.district+'<br/>' : '')+
				'<a href="'+(item.urlBase || helpers.routerUrls['locations'])+item.id+'">'+window.l('Nánar')+'</a></p>'
			);
			marker.bindTooltip(item.label);

			bounds.push(item.latLng);
			heatPoints.push([item.latLng[0], item.latLng[1], item.count || 20]);

			this.layerGroup.addLayer(marker);

			this.markers.push({
				id: item.id,
				type: item.type,
				marker: marker
			});
		});

		try {
			this.map.fitBounds(bounds, {
				maxZoom: 10,
				padding: [20, 20]
			});
		}
		catch (e) {}

		//this.heatLayer.setLatLngs(heatPoints);

		this.updateMapSize();
	}

	updateMapSize() {
		this.map.invalidateSize();
	}

	render() {
		return (
			<div className={'map-wrapper '+(this.props.className || '')}>
				<div ref={this.mapContainer} className="map-container" />
			</div>
		);
	}
}

export default MapView;
